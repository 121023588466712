
import { ethers } from 'ethers';

import Contract from '@/contract/PublicPrivateVoucherMint.json'

export default {
    namespaced: true,
    state: {        
        contract: { },
        message: { },
        state: { },
        params: { },
    },
    getters: {
        getContract: (state) => state.contract,
        getContractMessage: (state) => state.message,        
        getContractState: (state) => state.state,        
    },
    mutations: {
        setMessage (state, {addr, val}) { state.message[addr] = val },
        setContract(state, {addr, val}) { state.contract[addr] = val },
        setState(state, {addr, val}) {
            let data = { }
            if (val === null) {
                data = { 
                    isPublicMintActive: false,
                    isPrivateMintActive: false,
                    isVoucherMintActive: false,
                    publicMintPrice: "-",
                    privateMintPrice: "-",
                    maxPublicMintAmount: "-",
                    maxPrivateMintAmount: "-",
                    maxPublicRoundSupply: "-",
                    maxPrivateRoundSupply: "-",
                    currentPublicRoundSold: "-",
                    currentPrivateRoundSold: "-",
                    pastPublicRoundSold: "-",
                    pastPrivateRoundSold: "-",
                    totalVoucherClaimed: "-",
                    totalUnclaimedVoucher: "-",
                    balance: "-",
                }
            } else {                
                data = { 
                    isPublicMintActive: val[0] == 1? true : false,
                    isPrivateMintActive: val[1] == 1? true : false,
                    isVoucherMintActive: val[2] == 1? true : false,
                    publicMintPrice: val[3] / 1e18,
                    privateMintPrice: val[4] / 1e18,
                    maxPublicMintAmount: val[5] * 1.,
                    maxPrivateMintAmount: val[6] * 1.,
                    maxPublicRoundSupply: val[7] * 1.,
                    maxPrivateRoundSupply: val[8] * 1.,
                    currentPublicRoundSold: val[9] * 1.,
                    currentPrivateRoundSold: val[10] * 1.,
                    pastPublicRoundSold: val[11] * 1.,
                    pastPrivateRoundSold: val[12] * 1.,
                    totalVoucherClaimed: val[13] * 1.,
                    totalUnclaimedVoucher: val[14] * 1.,
                    balance: val[15] / 1e18,
                }
            }    
            state.state[addr] = data;            
        },
        setParams(state, {addr, val}) {
            state.params[addr] = val;
        }
    },
    actions: {
        async updateStatus({commit, rootState}, addr) {
            let contract = (addr in rootState.PublicPrivateVoucherMint.contract) ? rootState.PublicPrivateVoucherMint.contract[addr] : null;
            if (contract === null) return;
            let val = await contract.status()
            let balance = await rootState.web3.providerEthers.getBalance(addr) * 1.            
            commit("setState",{ addr, val: [...val, balance] })
        },
        async initContract({commit, rootState}, addr) {
            if (!rootState.web3.isConnected) {
                commit("setMessage" , { addr, val: ""})
                commit("setContract", { addr, val: null })
                commit("setState", {addr, val: null} )
                commit("setParams", { addr, val: rootState.web3 })
                return;
            }                        
            if ( addr in rootState.PublicPrivateVoucherMint.params &&
                 rootState.web3.chainId == rootState.PublicPrivateVoucherMint.params[addr].chainId && 
                 rootState.web3.activeAccount == rootState.PublicPrivateVoucherMint.params[addr].activeAccount ) return;                    
            try {                            
                let signer   = rootState.web3.providerEthers.getSigner()              
                let contract = new ethers.Contract(addr, Contract.abi, signer)                
                let val      = await contract.status()                
                let balance = await rootState.web3.providerEthers.getBalance(addr) * 1.                
                commit("setContract", { addr, val: contract })
                commit("setMessage", { addr, val: "" })
                commit("setParams", { addr, val: rootState.web3 })
                commit("setState",{ addr, val: [...val, balance] })
            } catch (e) {
                console.log(e)
                commit("setMessage" , { addr, val: "Network is not support" })
                commit("setContract", { addr, val: null })
                commit("setParams", { addr, val: rootState.web3 })
                commit("setState", {addr, val: null} )
            }
        }
    },
}


import axios from '@/libs/axios';
import useJwt from '@/auth/jwt/useJwt'



/**
 * {
      headers: {
        Authorization: `${token}` // `Bearer ${token}`
      }
 */

export default {
    namespaced: true,
    state: {        
        vouchers: [],
        voucherMap: {},
        ready: false,
    },
    getters: {
        vouchers: (state) => state.vouchers,
        voucherMap: (state) => state.voucherMap,
        isVoucherReady: (state) => state.ready,
    },
    mutations: {        
        setVouchers(state, vouchers) {             
            let _voucherMap = { }
            let _vouchers = [ ]
            vouchers.forEach((voucher)=>{
                _vouchers.push(voucher)
                _voucherMap[voucher.id] = voucher
            })
            state.ready = true         
            state.vouchers = _vouchers
            state.voucherMap = _voucherMap
        },
        updateVoucher(state, voucher) {
            Object.assign(state.voucherMap[voucher.id],voucher)
        },
        addVoucher(state, voucher) {
            state.vouchers.push(voucher)
            state.voucherMap[voucher.id] = voucher
        }        
    },
    actions: {
        async loadVouchers({commit}) {
            return axios.get('/voucher/browse', { headers: { Authorization: useJwt.getToken() }}).then((response) => {                
                commit("setVouchers", response.data.data)
            }).catch((error)=>{
                console.log(error)
            })
        },
        async updateVoucher({commit}, voucher) {            
            return axios.put('/voucher', voucher, { headers: { Authorization: useJwt.getToken() }}).then((response) => {                
                commit("updateVoucher", response.data.data)
            })
        },
        async createVoucher({commit}, voucher) {
            if ('id' in voucher) delete voucher['id']
            return axios.post('/voucher', voucher, { headers: { Authorization: useJwt.getToken() }}).then((response) => {
                console.log(response.data.data)
                commit("addVoucher", response.data.data)
            })
        }
    },
}
